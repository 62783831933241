<template>
	<div class="registerForm_box">
		<!-- 弹窗 -->
		<el-dialog :title="titlename" center width="50%" top="3vh" :visible.sync="dialogFormRegister"
			:close-on-click-modal="false" @close="closeLoginform">
			<el-form :model="formObj" :rules="rules">
				<el-form-item label="标题">
					<el-input v-model="formObj.title" placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<el-input v-model="formObj.contents" type="textarea" placeholder="请输入内容" rows="5"></el-input>
				</el-form-item>
				<el-form-item label="上传图片">
					<div class="content_imgbox">
						<div class="detaildiv">
							<draggable v-model="imagesList" class="image-list" @change="dragChange">
								<div v-for="(image, imgindex) in imagesList" :key="imgindex" class="image-wrap">
									<img :src="image" class="imgStyle" referrerPolicy="no-referrer">
									<div class="icon-wrap" style="cursor: pointer;" v-if="showedit">
										<div style="margin: 0 10px;"><i class="el-icon-delete" @click="removeimglist(imgindex)" /></div>
										<div style="margin: 0 10px;"><i class="el-icon-zoom-in" @click="checkimglist(imgindex)" /></div>
										<!-- <div style="margin: 0 10px;"><i class="el-icon-upload" @click="sendimglist(imgindex)" /></div> -->
									</div>
								</div>
								<el-upload v-if="showedit" class="avatar-uploaderde" action="" :http-request="handlelistSuccess" :show-file-list="false"
									:before-upload="beforelistUpload" multiple>
									<i class="el-icon-plus avatar-uploader-iconde" />
								</el-upload>
							</draggable>
							<div slot="tip" class="el-upload__tip" v-if="showedit">可拖拽图片换顺序，只能上传jpg/png文件，建议大小不超过1m，建议宽高835 x 368</div>
						</div>
						<!-- // 图片查看器 -->
						<el-image-viewer
						  v-if="showViewer"
						  :initial-index="imagesindex"
						  :on-close="closeViewer"
						  :zIndex="9999"
						  :url-list="checkimagesList" />
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeLoginform">返 回</el-button>
				<el-button type="primary" @click="FormSubmit" v-if="istype=='add'">提交发布</el-button>
				<el-button type="primary" @click="FormSubmit" v-if="istype=='edit'">修改发布</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {mapState} from 'vuex'
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	import draggable from 'vuedraggable'
	import ElImageViewer from "element-ui/packages/image/src/image-viewer";
	export default {
		name: 'discussForm',
		components: {
			draggable,
			ElImageViewer
		},
		props: {
			titlename: {
				type: String,
				default: () => {
					return "弹窗";
				}
			},
			isShow: {
				type: Boolean,
				default: () => {
					return false;
				}
			},
			showedit: {
				type: Boolean,
				default: () => {
					return true;
				}
			},
			istype: {
				type: String,
				default: () => {
					return "add";
				}
			},
			dataobj: {
				type: Object,
				default () {
					return {}
				}
			},
			groupId: {
				type: Number,
				default () {
					return 0
				}
			},
		},
		data() {
			return {
				formLabelWidth: '100%',
				rules: {
					afterstar: [{
						required: true,
						message: '此项为必填项',
						trigger: 'blur'
					}]
				},
				formObj: {
					title: "",
					contents:"",
					imgUrlList:""
				},
				dialogFormRegister: false,
				getCodeisWaiting: false, // 禁止多次点击
				imagesList: [],
				showViewer:false,
				checkimagesList:[],
				imagesindex:0,
			};
		},
		created() {

		},
		computed: {
			...mapState(['userInfo', 'isLogin']),
		},
		watch: {
			isShow(data) {
				this.dialogFormRegister = data
			},
			dataobj: {
				immediate: true, // immediate选项可以开启首次赋值监听
				handler(newVal, oldVal) {
					// console.log(newVal, oldVal)
					this.formObj = newVal
					if(newVal.imgUrlList){
						this.imagesList = JSON.parse(newVal.imgUrlList)
					}
				}
			}
		},
		methods: {
			//关闭登录弹窗的回调
			closeLoginform() {
				this.$emit("handleForm", 'close')
			},
			//提交
			FormSubmit() {
				var _this = this
				var params = this.formObj
				if (!params.title) {
					this.$message.error("请输入标题")
					return;
				}
				if (!params.contents) {
					this.$message.error("请输入内容")
					return;
				}
				params["imgUrlList"] = JSON.stringify(this.imagesList)
				params["userUuid"] = this.userInfo.uuid
				params["groupId"] = this.groupId
				params["auditStatus"] = 0
				showLoading();
				var control = "app/content/ctDiscussInfo/save"
				var controltext = "发布"
				if(this.istype=="edit"){
					control = "app/content/ctDiscussInfo/update"
					controltext = "修改"
				}
				this.$http.post(control, params).then(function(res) {
					//数据处理
					hideLoading();
					if (res.code == 200) {
						// _this.$alert(controltext+'话题讨论成功，请等待审核！', '温馨提示', {
						// 	confirmButtonText: '确定',
						// 	callback: action => {
						// 		_this.closeLoginform()
						// 		_this.formObj = {
						// 			title: "",
						// 			contents:"",
						// 			imgUrlList:""
						// 		}
						// 		_this.imagesList = []
						// 	}
						// });
						_this.$message.success(controltext+'话题成功')
						_this.closeLoginform()
						_this.formObj = {
							title: "",
							contents:"",
							imgUrlList:""
						}
						_this.imagesList = []
					}else{
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//多图查看
			checkimglist(index){
				//当前之前/当前之后
				let startArrImgs = this.imagesList.slice(index,this.imagesList.length)
				let endArrImgs = this.imagesList.slice(0,index)
				startArrImgs.push.apply(startArrImgs, endArrImgs)
				//【1，2，3】【4，5】---【1，2，3，4，5】
				// this.imagesindex = 0
				//console.log(startArrImgs)
				this.checkimagesList = startArrImgs
				this.showViewer = true
			},
			//关闭
			closeViewer(){
				this.imagesindex = 0
				this.checkimagesList = []
				this.showViewer = false
			},
			//发送
			sendimglist(imgindex) {
				this.$emit('sendimglist', imgindex)
			},
			dragChange(en) {
				console.log(en)
				//console.log(this.imagesList)
				//this.$emit('dragChange', this.imagesList)
			},
			// 删除多图轮播
			removeimglist(imgindex) {
				//this.$emit('removeimglist', imgindex)
				this.imagesList.splice(imgindex, 1)
			},
			async handlelistSuccess({
				file
			}) {
				var res = await this.$api.uploadHttp(file, {})
				if (res && res.status == 200) {
					//this.$emit('addimglist', res.requestUrls[0])
					this.imagesList.push(res.requestUrls[0])
					this.$message.success('上传图片成功!')
				} else {
					this.$message.error('上传图失败!')
				}
			},
			beforelistUpload(file) {
				console.log(file)
				const isLt2M = file.size / 1024 / 1024 < 10
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!')
					return false
				}
				if (file.type == 'image/jpeg' || file.type == 'image/png') {
					
				} else {
					this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
					return false
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.form_box {
		padding: 18px;
		background-color: #F9FBFF;
	}

	.formtips {
		font-size: 12px;
		font-weight: 400;
		color: #1672FB;
		cursor: pointer;
	}
	.content_imgbox {
		margin-bottom: 10px;
	}
	
	.image-list {
		display: flex;
		flex-wrap: wrap;
	}
	/deep/ .el-form-item__label{
		float: none !important;
	}
	.image-list .image-wrap {
		position: relative;
		display: inline-block;
		box-sizing: content-box;
		margin-right: 10px;
		line-height: 0;
		vertical-align: top;
		border-radius: 6px;
		overflow: hidden;
	}
	
	.image-list .imgStyle {
		width: 100px;
		height: 100px;
		border: 1px solid #d9d9d9;
	}
	
	.avatar-uploaderde {
		width: 100px;
		height: 100px;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
	}
	
	.avatar-uploader-iconde {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px !important;
		text-align: center;
	}
	
	.image-list .icon-wrap {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 30px;
		cursor: default;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		opacity: 0;
		font-size: 20px;
		background-color: rgba(0, 0, 0, 0.7);
		transition: opacity 0.3s;
		z-index: 8888;
	}
	
	.image-list .image-wrap:hover {
		.icon-wrap {
			opacity: 1;
		}
	}
	
	.image-list .el-icon-zoom-in {
		cursor: pointer;
		margin-right: 8px;
	}
	
	.image-list .el-icon-delete {
		cursor: pointer;
	}
</style>