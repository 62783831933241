<template>
	<div class="registerForm_box">
		<!-- 弹窗 -->
		<el-dialog :title="titlename" center width="68%" top="3vh" :visible.sync="dialogFormRegister"
			:close-on-click-modal="false" @close="closeLoginform">
			<div class="listbox">
				<div class="datalist" v-if="istype=='myset'">
					<div class="itemobj" v-for="(item,index) in dataList" :key="index">
						<div class="titlename fontsize16 clamp">{{item.title}}</div>
						<div class="timer fontsize14">{{item.createDate}}</div>
						<div class="btnbox fontsize14">
							<el-button  size="medium" type="primary" @click="handleedit(item)">重新编辑</el-button>
							<el-button size="medium" type="danger" @click="handledeldisuss(item)">删除</el-button>
						</div>
					</div>
				</div>
				<div class="datalist"  v-if="istype=='mysay'">
					<div class="itemobj" v-for="(item,index) in dataList" :key="index">
						<div class="titlename fontsize16 clamp">{{item.ctDiscussInfo.title}}</div>
						<div class="context fontsize14 clamp">我的留言：{{item.comment}}</div>
						<div class="timer fontsize14">{{item.createDate}}</div>
						<div class="btnbox fontsize14">
							<el-button size="medium" type="primary"  @click="handledisdetail(item)">查看详情</el-button>
							<el-button size="medium" type="danger" @click="handledelconmem(item)">删除</el-button>
						</div>
					</div>
				</div>
				<div class="fenyebox">
					 <el-pagination
					    @current-change="handleCurrentChange"
					    :current-page.sync="currentPage"
					    :page-size="pageSize"
					    layout="prev, pager, next, jumper"
					    :total="total">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	import {mapState} from 'vuex'
	export default {
		name: 'discussList',
		props: {
			titlename: {
				type: String,
				default: () => {
					return "我发布的话题";
				}
			},
			isShow: {
				type: Boolean,
				default: () => {
					return false;
				}
			},
			istype: {
				type: String,
				default: () => {
					return "";
				}
			},
			list: {
				type: Array,
				default: () => {
					return [];
				}
			},
			groupId: {
				type: Number,
				default () {
					return 0
				}
			},
		},
		data() {
			return {
				formLabelWidth: '100%',
				rules: {
					afterstar: [{
						required: true,
						message: '此项为必填项',
						trigger: 'blur'
					}]
				},
				dataList: [],
				currentPage:1,
				pageSize:3,
				total:0,//总数
				dialogFormRegister: false,
				getCodeisWaiting: false, // 禁止多次点击
			};
		},
		created() {
			
		},
		computed: {
			...mapState(['userInfo', 'isLogin']),
		},
		watch: {
			isShow(data) {
				this.dialogFormRegister = data
			},
			// list: {
			// 	immediate: true, // immediate选项可以开启首次赋值监听
			// 	handler(newVal, oldVal) {
			// 		this.dataList = newVal
			// 	}
			// }
		},
		methods: {
			//删除评论
			handledelconmem(item){
				var _this = this
				var params = {} 
				params["id"] = item.id
				params["userUuid"] = this.userInfo.uuid
				params["groupId"] = this.groupId
				showLoading()
				this.$http.post('app/content/ctCommentInfo/del', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						_this.$alert('删除话题评论成功！', '温馨提示', {
							confirmButtonText: '确定',
							callback: action => {
								//刷新
								_this.handleCurrentChange(1)
							}
						});
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//查看详情
			handledisdetail(item){
				this.$emit("handledisdetail",item)
			},
			//删除话题
			handledeldisuss(item){
				var _this = this
				var params = {} 
				params["id"] = item.id
				params["userUuid"] = this.userInfo.uuid
				params["groupId"] = this.groupId
				showLoading()
				this.$http.post('app/content/ctDiscussInfo/del', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						_this.$alert('删除讨论话题成功！', '温馨提示', {
							confirmButtonText: '确定',
							callback: action => {
								//刷新
								_this.handleCurrentChange(1)
							}
						});
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//重新编辑
			handleedit(item){
				this.$emit("handleedit",item)
			},
			//刷新数据
			refreshdata(){
				this.currentPage = 1
				this.getPagedata()
			},
			//关闭登录弹窗的回调
			closeLoginform() {
				this.$emit("handleForm", 'close')
			},
			//下一页
			handleCurrentChange(currentPage){
				this.currentPage = currentPage
				this.getPagedata()
			},
			//目录
			getPagedata() {
				var _this = this
				var params = {}
				params["currentPage"] = this.currentPage
				params["pageSize"] = this.pageSize
				params["userUuid"] = this.userInfo.uuid
				params["groupId"] = this.groupId
				var contol = 'app/content/ctDiscussInfo/indexMy'
				if(this.istype == "mysay"){
					contol = 'app/content/ctCommentInfo/indexMy'
				}
				this.$http.post(contol, params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						_this.dataList = records
						_this.total = res.data.total;
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			
		}
	};
</script>
<style lang="scss" scoped>
	.listbox {
		width: 100%;
		.fenyebox{
			height: 58px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		.datalist{
			width: 100%;
			.itemobj{
				width: 100%;
				border-radius: 4px;
				border: 1px solid #E0E0E0;
				background: #FFF;
				box-sizing: border-box;
				padding: 20px;
				margin-bottom: 12px;
				.titlename{
					width: 100%;
					color: #333;
				}
				.context{
					margin-top: 10px;
					width: 100%;
					color: #222;
				}
				.timer{
					margin-top: 10px;
					color: #999;
				}
				.btnbox{
					width: 100%;
					margin-top: 10px;
					display: flex;
					justify-content: flex-end;
				}
			}
		}
	}

	
</style>